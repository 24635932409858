import React from 'react'
import { WELL_KNOWN_DOMAINS } from '~/config'
import { useHistory } from '~/history'
import {
  handleIntent,
  isExternalHref,
  isIntentHref,
  resolveHref,
  resolveHrefOnServer,
  shouldResetHistory,
  shouldResolveHref,
} from '~/navigation'
import { observer } from '~/ui/component'
import { TappableContainer } from '~/ui/components/tappable'
import { useBoolean, useQueryParam } from '~/ui/hooks'
import InAppBrowser from './InAppBrowser'
import { parseMessageData } from './util'

export interface Props {
  children?: React.ReactNode
}

const AppNavigationContainer = observer('AppNavigationContainer', (props: Props) => {

  const {children, ...rest} = props

  const history = useHistory()

  const [inAppBrowserOpen, openInAppBrowser, closeInAppBrowser] = useBoolean()
  const [inAppBrowserUrl, setInAppBrowserUrl] = React.useState<string | null>(null)

  const targetForHref = React.useCallback((href) => {
    if (shouldResolveHref(href)) { return }

    // Any external link will be opened in a new tab
    return '_bank'
  }, [])

  const shouldUseDefault = React.useCallback((href: string, target: string | undefined, event: React.SyntheticEvent) => {
    if (!href.startsWith('?resolveOnServer=') && target != null && target !== '') { return true }

    // Browsers have a default handler for cmd, or ctrl clicking a link.
    // We need to let the browsers take care of that and not consider this a tap.
    const mouseEvent = event as React.MouseEvent | React.TouchEvent
    if (mouseEvent.metaKey || mouseEvent.altKey || mouseEvent.ctrlKey) {
      return true
    }

    return false
  }, [])

  const openWithInAppBrowser = React.useCallback((href: string) => {
    setInAppBrowserUrl(href)
    openInAppBrowser()
  }, [openInAppBrowser])

  const resolveHrefOnServerAndNavigate = React.useCallback(async (href: string, target?: string) => {
    const newTab = target === '_blank' ? window.open() : null
    const resolved = await resolveHrefOnServer(href)
    if (resolved == null) { return }

    if (target === '_blank' && newTab != null) {
      newTab.location = resolved
    } else {
      openWithInAppBrowser(resolved)
    }
  }, [openWithInAppBrowser])

  const navigate = React.useCallback((href: string, target: string | undefined = undefined, event?: React.SyntheticEvent) => {
    if (event != null && shouldUseDefault(href, target, event)) { return }
    event?.preventDefault()

    closeInAppBrowser()

    if (isIntentHref(href)) {
      handleIntent(href)
    } else if (/^\?resolveOnServer=(.*)/.test(href)) {
      resolveHrefOnServerAndNavigate(decodeURIComponent(RegExp.$1), target)
    } else if (isExternalHref(href)) {
      window.open(href, '_self')
    } else if (shouldResetHistory(window.location.pathname, href)) {
      history.reset(`${href}${history.location.hash}`)
    } else {
      history.push(`${href}${history.location.hash}`)
    }
  }, [shouldUseDefault, closeInAppBrowser, resolveHrefOnServerAndNavigate, history])

  const [resolveOnServer, setResolveOnServer] = useQueryParam('resolveOnServer', 'string')

  React.useEffect(() => {
    if (resolveOnServer == null) { return }
    resolveHrefOnServerAndNavigate(resolveOnServer)
    setResolveOnServer(null)
  }, [resolveOnServer, setResolveOnServer, resolveHrefOnServerAndNavigate])

  //------
  // Messaging

  const handleMessage = React.useCallback((message: MessageEvent) => {
    const trustedOrigin = Object.keys(WELL_KNOWN_DOMAINS).find(domain => message.origin.endsWith(domain))
    if (!trustedOrigin) { return }

    const event = parseMessageData(message.data)
    if (event == null) { return }

    if (event.type === 'navigate') {
      const href = resolveHref(event.data)
      if (!isIntentHref(href)) { return }

      navigate(href)
    }
  }, [navigate])

  React.useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    return () => window.removeEventListener('message', handleMessage, false)
  }, [handleMessage])

  //------
  // Rendering

  function render() {
    return (
      <TappableContainer
        targetForHref={targetForHref}
        resolveHref={resolveHref}
        navigate={navigate}
        {...rest}
      >
        {renderInAppBrowser()}
        {children}
      </TappableContainer>
    )
  }

  function renderInAppBrowser() {
    return (
      <InAppBrowser
        open={inAppBrowserOpen}
        url={inAppBrowserUrl}
        requestClose={closeInAppBrowser}
      />
    )
  }

  return render()

})

export default AppNavigationContainer