import React from 'react'
import { merge } from 'lodash'
import * as SM from 'simple-markdown'
import { cleanTextValue } from 'ytil'
import { extractVimeoVideoID } from '~/ui/app/chat/bubbles/VimeoBubble'
import { extractYouTubeVideoID } from '~/ui/app/chat/bubbles/YouTubeBubble'
import VimeoPlayer from './VimeoPlayer'
import YouTubePlayer from './YouTubePlayer'
import config from './config'
import { ImageNode, VariableNode } from './types'

export default function buildRules() {
  return merge({}, SM.defaultRules, {
    image: {
      parse: (capture: RegExpExecArray, parse: SM.Parser, state: SM.State): ImageNode => {
        let fullWidth = false
        let caption   = cleanTextValue(capture[1], true)
        if (caption != null && caption[0] === '[' && caption[caption.length - 1] === ']') {
          caption = caption?.slice(1, -1)
          fullWidth = true
        }

        return {
          type:   'image',
          target: capture[2],
          alt:    caption ?? '',
          title:  caption,
          style:  {fullWidth},
        }
      },

      react: (node: ImageNode, output: SM.Output<any>, state: SM.State) => {
        const {target, alt, title, style} = node
        const classNames = {fullWidth: style.fullWidth}

        return (
          <figure key={state.key} classNames={classNames} title={title}>
            <img src={target} alt={alt}/>
          </figure>
        )
      },
    },

    variable: {
      order: SM.defaultRules.inlineCode.order,

      match: (source: string) => /^\{\{(.*?)\}\}/.exec(source),

      parse: (capture: SM.Capture, parse: any, state: SM.State) => {
        return {
          type:     'variable',
          variable: capture[1],
        }
      },

      react: (node: VariableNode, output: SM.Output<any>, state: SM.State) => {
        return (
          <var key={state.key}>
            {`{{${node.variable}}}`}
          </var>
        )
      },
    },

    youtube: {
      order: SM.defaultRules.paragraph.order - 1,

      match(source: string) {
        const id = extractYouTubeVideoID(source)
        if (id == null) { return null }

        return [source, id]
      },

      parse: (capture: SM.Capture, parse: any, state: SM.State) => {
        return {
          type:    'youtube',
          videoID: capture[1],
        }
      },

      react: (node: YouTubeNode, output: SM.Output<any>, state: SM.State) => {
        return (
          <YouTubePlayer
            key={state.key}
            videoId={node.videoID}
          />
        )
      },
    },

    vimeo: {
      order: SM.defaultRules.paragraph.order - 1,

      match(source: string) {
        const id = extractVimeoVideoID(source)
        if (id == null) { return null }

        return [source, id]
      },

      parse: (capture: SM.Capture, parse: any, state: SM.State) => {
        return {
          type:    'vimeo',
          videoID: capture[1],
        }
      },

      react: (node: YouTubeNode, output: SM.Output<any>, state: SM.State) => {
        return (
          <VimeoPlayer
            key={state.key}
            video={node.videoID}
          />
        )
      },
    },

  }, config.extraRules)
}

interface YouTubeNode {
  type:    'youtube'
  videoID: string
}
