import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { memo, observer } from '~/ui/component'
import { Center, Spinner } from '~/ui/components'
import { AppLayout } from '~/ui/layouts'
import BeWizrCourseScreen from '../bewizr/BeWizrCourseScreen'
import BeWizrCoursesScreen from '../bewizr/BeWizrCoursesScreen'
import BeWizrLearningTrackScreen from '../bewizr/BeWizrLearningTrackScreen'
import BeWizrLibraryCategoryScreen from '../bewizr/BeWizrLibraryCategoryScreen'
import BeWizrLibraryScreen from '../bewizr/BeWizrLibraryScreen'
import BeWizrLibraryTopicScreen from '../bewizr/BeWizrLibraryTopicScreen'
import ChallengeScreen from '../challenges/ChallengeScreen'
import ChallengesScreen from '../challenges/ChallengesScreen'
import ChallengeTaskScreen from '../challenges/tasks/ChallengeTaskScreen'
import ConnectionsScreen from '../connections/ConnectionsScreen'
import InfoScreen from '../info/InfoScreen'
import NewsScreen from '../news/NewsScreen'
import PostScreen from '../news/PostScreen'
import NotificationsScreen from '../notifications/NotificationsScreen'
import ParticipantScreen from '../participants/ParticipantScreen'
import ProfileScreen from '../profile/ProfileScreen'
import QAndAScreen from '../q-and-as/QAndAScreen'
import RankingsScreen from '../rankings/RankingsScreen'
import SearchScreen from '../search/SearchScreen'

export interface Props {}

const RootNavigator = observer('RootNavigator', (props: Props) => {

  return (
    <AppLayout>
      <Switch>
        <Route exact path='/login' component={TransitionScreen}/>
        <Route path='/auth' component={TransitionScreen}/>
        <Route path='/profile' component={ProfileScreen}/>
        <Route path='/notifications' component={NotificationsScreen}/>
        <Route path='/search/:resource?' component={SearchScreen}/>
        <Route path='/-/*' component={InfoScreen}/>
        <Route path='/news' exact component={NewsScreen}/>
        <Route path='/posts/-/:id' component={PostScreen}/>
        <Route path='/challenges/-/:challengeID/tasks/:taskID' component={ChallengeTaskScreen}/>
        <Route path='/challenges/-/:id' component={ChallengeScreen}/>
        <Route path='/challenges' component={ChallengesScreen}/>
        <Route path='/q-and-as/-/:id' component={QAndAScreen}/>
        <Route path='/connections' component={ConnectionsScreen}/>
        <Route path='/participants/-/:id' component={ParticipantScreen}/>
        <Route path='/rankings/-/:competitionID' component={RankingsScreen}/>
        <Route path='/bewizr-courses/-/:slug' component={BeWizrCourseScreen}/>
        <Route path='/bewizr-courses/learning-tracks/-/:id' component={BeWizrLearningTrackScreen}/>
        <Route path='/bewizr-courses' component={BeWizrCoursesScreen}/>
        <Route path='/bewizr-library/-/:topic/:slug' component={BeWizrLibraryCategoryScreen}/>
        <Route path='/bewizr-library/-/:slug' component={BeWizrLibraryTopicScreen}/>
        <Route path='/bewizr-library' component={BeWizrLibraryScreen}/>
      </Switch>
    </AppLayout>
  )

})

interface TransitionScreenProps {}

const TransitionScreen = memo('TransitionScreen', (props: TransitionScreenProps) => {

  function render() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

export default RootNavigator