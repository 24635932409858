import React from 'react'
import { memo } from '~/ui/component'
import { Tappable, TappableProps } from '~/ui/components'
import { Label, LabelProps } from './label'

export interface Props extends LabelProps {
  href:   TappableProps['href']
  onTap?: TappableProps['onTap']
}

const LinkLabel = memo('LinkLabel', (props: Props) => {

  const {href, onTap, link = true, ...rest} = props

  return (
    <Tappable href={href} onTap={onTap} noFeedback>
      <Label link={link} {...rest}/>
    </Tappable>
  )

})

export default LinkLabel