import socket from 'socket.io-react'
import { switchRegExp } from 'ytil'
import { projectStore } from '~/stores'
import config from '../config'
import HrefResolver from './HrefResolver'

export function shouldResolveHref(href: string) {
  if (isInternalHref(href) || HrefResolver.shouldResolve(href) || shouldResolveHrefOnServer(href)) {
    return true
  }
  return false
}

export function resolveHref(href: string) {
  if (isAppHref(href)) { return resolveAppHref(href) }
  if (isInternalHref(href)) { return href.replace(config.urls.app, '') }

  if (HrefResolver.shouldResolve(href)) {
    return HrefResolver.resolve(href)
  }

  // If a link needs to be resolved server side, it will be resolved upon navigation
  // We add a query param so we know it still needs to be resolved
  // And to support opening the link in a new tab
  if (shouldResolveHrefOnServer(href)) { return `?resolveOnServer=${encodeURIComponent(href)}` }

  return href
}

export function resolveAppHref(href: string) {
  if (!isAppHref(href)) { return href }

  return switchRegExp(href, [
    [/^\/\/info/,                    () => '/-/'],
    [/^\/\/page\/(.*)/,              m  => `/-/${m[1]}`],
    [/^\/\/chat/,                    () => '/chat'],
    [/^\/\/feed\/(.*)/,              m => `/news?feeds=${m[1]}`],
    [/^\/\/post\/(.*)/,              m  => `/posts/-/${m[1]}`],
    [/^\/\/news(?:\?feedID=(.+))?$/, m => m[1] == null ? `/news` : `/news?feeds=${m[1]}`],
    [/^\/\/feed/,                    () => '/news'],
    [/^\/\/challenges/,              () => '/challenges'],
    [/^\/\/challenges\/(.*)$/,       m  => `/challenges/${m[1]}`],
    [/^\/\/challenge\/(.*)$/,        m  => `/challenges/-/${m[1]}`],
    [/^\/\/q-and-a\/(.*)?$/,         m  => `/q-and-as/-/${m[1]}`],
    [/^\/\/participant\/(.*)$/,      m  => `/participants/-/${m[1]}`],
    [/^\/\/connections/,             () => '/connections'],
    [/^\/\/profile/,                 () => '/profile'],
    [/^\/\/rankings\/(.*)$/,         m  => `/rankings/-/${m[1]}`],
    [/^\/\/search/,                  () => '/search'],
    [/^\/\/trigger\/(.*)$/,          m  => `/trigger/${m[1]}`],

    [/^\/\/bewizr-learning-track\/(.*)$/, m  => `/bewizr-courses/learning-tracks/-/${m[1]}`],
    [/^\/\/bewizr-course\/(.*)$/,         m  => `/bewizr-courses/-/${m[1]}`],
    [/\/\/bewizr-courses/,                () => '/bewizr-courses'],
    [/^\/\/bewizr-library-topic\/(.*)$/,  m  => `/bewizr-library/-/${m[1]}`],
    [/^\/\/bewizr-library/,               () => '/bewizr-library'],
  ],
  () => {
    console.warn("Could not resolve project HREF:", href)
    return href
  })
}

export function isAppHref(href: string) {
  if (href.startsWith('//')) { return true }

  const match = href.match(/^([-_+\w\d]+):/)
  if (match == null) { return false }

  const projectURLScheme = [
    'groundcontrol',
    projectStore.project?.urlScheme,
  ]

  return projectURLScheme.includes(match[1])
}

export function isExternalHref(href: string) {
  return !isInternalHref(href)
}

export function isInternalHref(href: string) {
  if (isAppHref(href)) { return true }

  // Any link that doesn't have a protocol is an internal link.
  const match = href.match(/^[\d\w.+-]+:/)
  if (match == null) { return true }

  // If it starts with the current app URL, it's also internal.
  if (href.startsWith(config.urls.app)) { return true }
}

export function getRelatedAppHrefs(href: string): string[] {
  const hrefs: string[] = []

  if (/^\/\/posts/.test(href)) {
    hrefs.push('//news')
  } else if (/^\/\/news/.test(href)) {
    hrefs.push('//post/')
  }

  return hrefs
}

export function isIntentHref(href: string) {
  if (href.startsWith('/trigger')) { return true }
  return false
}

export async function handleIntent(href: string) {
  const response = await socket.send('camera:check', `groundcontrol:/${href}`)
  return response.ok ? response.body : null
}

export function shouldResolveHrefOnServer(href: string) {
  if (isInternalHref(href)) { return false }

  try {
    const url = new URL(href)
    return projectStore.shouldResolveURLScheme(url.protocol.replace(/:$/, ''))
  } catch (_) {
    console.warn("Unvalid URL encountered", href)
    return false
  }
}

export async function resolveHrefOnServer(href: string): Promise<string | null> {
  const response = await socket.send('link:resolve', href)
  return response.ok ? response.body : null
}

export function shouldResetHistory(currentHref: string, nextHref: string) {
  if (!isInternalHref(nextHref)) { return false }

  const currentTab = projectStore.activeTabForPath(currentHref)
  const nextTab    = projectStore.activeTabForPath(nextHref)

  if (/^\/search/.test(currentHref)) {
    return false
  } else if (/^\/bewizr-library/.test(currentHref) && /^\/bewizr-courses/.test(nextHref)) {
    return false
  }

  return nextTab != null && currentTab !== nextTab
}