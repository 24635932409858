import { initReactI18next } from 'react-i18next'
import I18n from 'i18next'
import * as locales from '~/locales'

I18n
  .use(initReactI18next)
  .init({
    fallbackLng:   'nl',
    resources:     locales,
    returnObjects: true,
    interpolation: {escapeValue: false},

    detection: {
      order:  ['profile'],
    },
  })