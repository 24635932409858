import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, LinkLabel, VBox } from '~/ui/components'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import { Breadcrumb } from './types'

export interface Props {
  breadcrumbs: Breadcrumb[]
  limit?:      number
  onTap?:      (breadcrumb: Breadcrumb, index: number, event: React.SyntheticEvent) => any
}

const Breadcrumbs = memo('Breadcrumbs', (props: Props) => {

  const {breadcrumbs, onTap, limit = 4} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox tag='nav' aria-label='breadcrumbs'>
        <HBox gap={itemGap} tag='ol' classNames={$.list}>
          {breadcrumbs.length > limit ? (
            <>
              {renderItem(breadcrumbs[0],0)}
              <HBox tag='li' classNames={$.item}>
                <Label small dimmer children='…'/>
              </HBox>
              {breadcrumbs.slice(breadcrumbs.length - limit + 1, breadcrumbs.length).map((breadcrumb, index) => (
                renderItem(breadcrumb, index)
              ))}
            </>
          ) : breadcrumbs.map(renderItem)}
        </HBox>
      </VBox>
    )
  }

  function renderItem(item: Breadcrumb, index: number) {
    return (
      <BreadcrumbItem
        key={`${index}${item.caption}`}
        item={item}
        index={index}
        onTap={onTap}
      />
    )
  }

  return render()

})

export default Breadcrumbs

export const itemGap = layout.padding.inline.m

interface BreadcrumbItemProps {
  item:   Breadcrumb
  index:  number
  onTap?: (breadcrumb: Breadcrumb, index: number, event: React.SyntheticEvent) => any
}

const BreadcrumbItem = memo('BreadcrumbItem', (props: BreadcrumbItemProps) => {

  const {item, index, onTap: props_onTap} = props

  const $ = useStyles()

  const onTap = React.useCallback((event: React.SyntheticEvent) => {
    props_onTap?.(item, index, event)
  }, [item, index, props_onTap])

  return (
    <HBox tag='li' classNames={$.item} key={index}>
      {item.href != null ? (
        <LinkLabel small href={item.href} onTap={onTap}>
          {item.caption}
        </LinkLabel>
      ) : (
        <Label small bold>
          {item.caption}
        </Label>
      )}
    </HBox>
  )

})

const useStyles = createUseStyles(theme => ({
  list: {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },

  item: {
    '&:not(:first-child)': {
      '&:before': {
        content: '"/"',
        paddingRight: itemGap,
        color: theme.colors.fg.dimmer,
        ...fonts.responsiveFontStyle(theme.guide.fonts.mono),
      },
    },
  },
}))