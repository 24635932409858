import React from 'react'
import { NotificationComponentProps, registerNotification } from 'react-notifications'
import { useHistory } from '~/history'
import { Channel, Message, Sender } from '~/models'
import { ChatService, projectStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { useChatService } from '../ChatContainer'
import NotificationPanel, { imageSize } from './NotificationPanel'

export interface Props {
  message: Message
  sender:  Sender
  channel: Channel
}

const ChatMessageNotification = memo('ChatMessageNotification', (props: Props & NotificationComponentProps) => {

  const {message, sender, channel, hide} = props
  const service = useChatService()

  const history = useHistory()
  const goToChannelAndHide = React.useCallback(() => {
    service?.requestSwitchChannel(channel.id)
    history?.push('/chat')
    hide()
  }, [channel.id, hide, history, service])

  const senderName = React.useMemo(
    () => [sender.firstName, sender.lastName].filter(Boolean).join(' '),
    [sender.firstName, sender.lastName],
  )

  //------
  // Rendering

  function render() {
    return (
      <NotificationPanel
        title={senderName}
        body={message.notificationText('received-short', sender)}
        avatar={renderAvatar()}
        onTap={goToChannelAndHide}
      />
    )
  }

  function renderAvatar() {
    return (
      <Avatar
        source={sender.photoURL}
        firstName={sender.firstName}
        lastName={sender.lastName}
        size={imageSize}
      />
    )
  }

  return render()

})

export default ChatMessageNotification

export function registerChatMessageNotification(service: ChatService) {
  return registerNotification(ChatMessageNotification, show => {
    return service.addIncomingMessageListener(({message, sender, channel}) => {
      if (sender.id === service.sender?.id) { return }
      if (isViewingChannel(channel.id, service)) { return }

      const imageURL = sender.photoURL
        ?? (channel.image?.type === 'remote' ? channel.image.url : undefined)
        ?? undefined

      show(channel.id, {
        message: message,
        sender:  sender,
        channel: channel,
      }, {
        browser: {
          icon:  imageURL,
          title: [sender.firstName, sender.lastName].filter(Boolean).join(' '),
          body:  message.notificationText('received-short', sender),
          tag:   channel.id,
        },
      })
    })
  })
}

function isViewingChannel(channelID: string, chatService: ChatService) {
  if (!isChatVisible()) { return false }

  // If we're looking at the channel list, it's also obvious that chat messages are coming in.
  if (chatService.currentChannelID == null) { return true }

  return chatService.currentChannelID === channelID
}

function isChatVisible() {
  const layout = projectStore.project?.web.layout

  if (layout === 'full') {
    return true
  } else {
    const url = new URL(document.location.href)
    return url.pathname === '/chat'
  }
}